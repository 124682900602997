@import 'variables';
@import 'mixins';

@import 'billboard.js/dist/billboard.css';

.bb {
  &.no-grid {
    .bb-axis.bb-axis-x {
      path.domain {
        visibility: hidden;
      }
    }
  }
}

.bb-chart-text {
  text.bb-text {
    @include app-caption-1;
    color: $grey-600;
    transform: translateY(-$grid-space-1);
  }
}

.bb-tooltip-container {
  display: flex;
  @include app-tooltip;
}

.basic-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.group-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
  .title {
    @include app-body-1;
    font-family: $font-regular;
    text-align: center;
    margin-bottom: $grid-space-1;
  }

  .value {
    @include app-body-1;
    @include row($vCenter: true);
    .color {
      height: 2px;
      width: 20px;
      margin-right: $grid-space-2;
    }

    &:first-child {
      margin-top: $grid-space-3;
    }

    &:not(:first-child) {
      margin-top: $grid-space-1;
    }
  }
}

.bb-chart-arc path {
  stroke-width: 0;

  &:hover {
    stroke-width: 3px;
  }
}

.bb-focused.bb-chart-arc path {
  stroke-width: 3px;
}

.bb-axis-y,
.bb-axis-y2 {
  .domain {
    display: none;
  }

  .tick {
    line {
      display: none;
    }

    text {
      font-family: $font-light;
      font-size: rem(12px);
      fill: $grey-600;
    }
  }
}

.bb-axis-x {
  .domain {
    stroke: $grey-080;
  }

  .tick {
    line {
      display: none;
    }

    text {
      transform: translateY(6px);
      font-family: $font-light;
      font-size: rem(12px);
      fill: $black;
    }
  }
}

.bb-text {
  font-size: rem(12px);
  fill: $grey-800 !important;
}

.chart-container {
  width: 100%;
  height: 200px;
}

line {
  &.bb-ygrid {
    stroke: $grey-080 !important;
    stroke-dasharray: 1;
  }
}

.bb-chart-lines {
  .bb-line {
    stroke-width: 2px;
  }

  .bb-circle {
    r: 0;
  }

  .bb-areas {
    path {
      opacity: 0.1 !important;
    }
  }
}

// Note(hadrien) Hack to make the last label on x axis visible
// otherwise the label is truncated by billboard.js
.bb.timeseries .bb-axis-x .tick:last-of-type text {
  transform: translate(-16px, 6px) !important;
}

.lefty-bubble-charts {
  .bb-shape.bb-shape-0.bb-circle {
    transition: all 300ms;
  }

  .bb-chart-texts text {
    @include app-heading-4;
  }
}
